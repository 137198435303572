/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module button {
  margin-right: 8px !important;
  margin-top: 6px; }

.module .buttonLink {
  background: none;
  border: none;
  padding: 0 !important;
  font-weight: bold;
  color: #413c6b;
  cursor: pointer;
  box-sizing: border-box; }
  .module .buttonLink:hover {
    box-sizing: border-box;
    text-decoration: underline; }

.module .buttonGreen {
  color: #ffffff;
  background-color: #11c26f; }
  .module .buttonGreen:hover {
    background-color: #0fa063; }

.module .mainButton {
  border-radius: 18px;
  width: 100%; }

.module .input, .module .select {
  background-color: #f5f5f5;
  border: solid 1px #cbcbcb;
  padding: 12px;
  margin-top: 6px; }

.module .button {
  background-color: #413c6b;
  color: #ffffff;
  height: 36px;
  border-radius: 18px; }
  .module .button.lg {
    height: 48px;
    border-radius: 24px; }

.module .select {
  height: 48px; }

/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  font-family: "PT Serif", serif;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  height: 100%;
  width: 100%; }

.background {
  position: absolute;
  z-index: -100;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
  background: url("/resources/images/wave.svg") no-repeat center center;
  background-size: cover;
  background-color: #29244e; }

.module .spacingContainer {
  padding-top: 20px; }

.module .spacingText {
  padding-top: 4px; }

.module .card {
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #cbcbcb;
  padding: 8px; }
  .module .card .main {
    color: #ffffff;
    border: none;
    padding: 12px; }
    .module .card .main .mainCardText {
      color: #ffffff;
      font-family: "Open Sans", sans-serif; }
  .module .card .mainCardTitle {
    font-size: 38px;
    letter-spacing: 1px;
    font-family: "Open Sans", sans-serif;
    font-weight: bolder; }

.module .title {
  font-family: "PT Serif", serif;
  font-size: 32px;
  font-weight: bold; }
  .module .title.titleWhite {
    color: #ffffff; }

.module .completeWidth {
  width: 100%; }

.module a.link {
  cursor: pointer;
  color: #198ce5; }
  .module a.link:hover {
    text-decoration: underline; }

.module .tableRight td {
  text-align: right; }

/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module button {
  margin-right: 8px !important;
  margin-top: 6px; }

.module .buttonLink {
  background: none;
  border: none;
  padding: 0 !important;
  font-weight: bold;
  color: #413c6b;
  cursor: pointer;
  box-sizing: border-box; }
  .module .buttonLink:hover {
    box-sizing: border-box;
    text-decoration: underline; }

.module .buttonGreen {
  color: #ffffff;
  background-color: #11c26f; }
  .module .buttonGreen:hover {
    background-color: #0fa063; }

.module .mainButton {
  border-radius: 18px;
  width: 100%; }

.module .input, .module .select {
  background-color: #f5f5f5;
  border: solid 1px #cbcbcb;
  padding: 12px;
  margin-top: 6px; }

.module .button {
  background-color: #413c6b;
  color: #ffffff;
  height: 36px;
  border-radius: 18px; }
  .module .button.lg {
    height: 48px;
    border-radius: 24px; }

.module .select {
  height: 48px; }

/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  font-family: "PT Serif", serif;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  height: 100%;
  width: 100%; }

.background {
  position: absolute;
  z-index: -100;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
  background: url("/resources/images/wave.svg") no-repeat center center;
  background-size: cover;
  background-color: #29244e; }

.module .spacingContainer {
  padding-top: 20px; }

.module .spacingText {
  padding-top: 4px; }

.module .card {
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #cbcbcb;
  padding: 8px; }
  .module .card .main {
    color: #ffffff;
    border: none;
    padding: 12px; }
    .module .card .main .mainCardText {
      color: #ffffff;
      font-family: "Open Sans", sans-serif; }
  .module .card .mainCardTitle {
    font-size: 38px;
    letter-spacing: 1px;
    font-family: "Open Sans", sans-serif;
    font-weight: bolder; }

.module .title {
  font-family: "PT Serif", serif;
  font-size: 32px;
  font-weight: bold; }
  .module .title.titleWhite {
    color: #ffffff; }

.module .completeWidth {
  width: 100%; }

.module a.link {
  cursor: pointer;
  color: #198ce5; }
  .module a.link:hover {
    text-decoration: underline; }

.module .tableRight td {
  text-align: right; }

.module .editGeneric, .module .edit, .module .editLight {
  border: none;
  padding: none;
  margin: 4px;
  background: none;
  font-size: 16px; }

.module .edit {
  color: #198ce5;
  margin-left: 8px;
  font-size: 18px; }
  .module .edit:hover {
    color: #44a8f5; }

.module .editLight {
  border: none;
  padding: none;
  margin: none;
  color: #ffffff;
  margin-left: 8px;
  font-size: 20px; }
  .module .editLight:hover {
    color: #feff7c; }

.module .cardSectionBlue label {
  color: white; }

.module {
  font-family: "PT Serif", serif;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  height: 100%;
  width: 100%; }

.module .mainCard {
  background: #FF5F00;
  color: #fff; }

.module .mainCard a {
  color: #fff; }

.module .profileImageContainer {
  width: 180px;
  height: 180px;
  -webkit-border-radius: 180px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 180px;
  -moz-background-clip: padding;
  border-radius: 180px;
  background-clip: padding-box;
  background-size: cover;
  background-position: center center;
  margin: 0 auto;
  float: none;
  border: 3px solid #fff; }

.module .openButton {
  background-color: #11c26f;
  color: #ffffff;
  min-height: 32px !important;
  width: 120px;
  margin-left: 10px;
  border-radius: 24px;
  font-size: 15px;
  margin-top: -4px; }
  .module .openButton:hover {
    color: #ffffff;
    background-color: #0fa063; }

.module .username {
  margin: 0 auto;
  float: none;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-top: 16px; }

.module .marginTop, .module .cardSection {
  margin-top: 16px; }

.module .paddingBottom, .module .cardSection {
  padding-bottom: 6px; }

.module .cardSection {
  border-bottom: 1px solid #f7f7f7; }

.module .list {
  list-style-type: none; }

.module .skillsHeader {
  padding: 28px;
  background-color: #413c6b;
  color: #ffffff;
  border-radius: 4px; }
  .module .skillsHeader .title {
    font-size: 18px;
    font-family: "PT Serif", serif; }

.module .greyBox {
  padding: 12px; }

.module .greyBox td {
  border-right: 1px solid #ccc; }

.module .transparentBox {
  padding: 12px 0px; }

.module .transparentBox td {
  border-right: 1px solid #ccc;
  padding: 12px; }

.module .flexHorz {
  display: flex;
  justify-content: left;
  flex-direction: row; }

.module .bigList .element {
  border-bottom: 1px solid #cbcbcb;
  padding: 6px; }

.module .bigList .link {
  color: #413c6b;
  font-family: "PT Serif", serif;
  font-size: 18px; }

.module .bigList .title {
  font-size: 18px; }

.module .table {
  text-align: center;
  font-size: 18px; }
  .module .table td {
    text-align: center;
    padding: 6px;
    padding-right: 12px;
    padding-left: 12px;
    font-size: 16px; }
  .module .table .number {
    font-weight: bold; }
  .module .table .validated {
    color: #11c26f; }
  .module .table .unvalid {
    color: #f96276; }
  .module .table .untested {
    color: #6c757d; }

.module .progress {
  background-color: #29244e;
  height: 20px;
  margin-bottom: 10px; }

.module .progressPrimary,
.module .progressSecondary {
  background-color: rgba(255, 255, 255, 0.4);
  cursor: pointer; }

.module .progressPrimary:hover,
.module .progressSecondary:hover {
  background-color: white;
  color: #29244e;
  font-weight: bold; }

.module .cardTitle {
  display: inline-block;
  background-color: #eee;
  border-radius: 4px;
  color: #444;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px; }

.sidebarItem {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  @media (max-width: 1200px) {
    .sidebarItem {
      height: unset;
      width: unset;
      position: unset; } }

.makeMeSticky {
  position: -webkit-sticky;
  position: sticky;
  top: 10px; }
  @media (max-width: 1200px) {
    .makeMeSticky {
      position: unset; } }

.module .formTable {
  width: 100%; }
  .module .formTable td {
    vertical-align: center;
    text-align: center !important;
    padding-top: 8px;
    padding-bottom: 8px; }
  .module .formTable th {
    text-align: center !important; }

.buttonGreenLight {
  color: #11c26f;
  border: 1px solid #11c26f; }

.buttonGreen {
  color: white;
  background: #11c26f;
  border: none; }

.module .input, .module .select {
  margin-bottom: 5px; }

.module .input.file, .module .file.select {
  background: none;
  border: none;
  padding: 0px; }

.error {
  width: 100%;
  margin-top: -10px;
  margin-bottom: 10px;
  background-color: #ffeeee;
  color: #a05050;
  font-size: 14px;
  padding: 12px;
  font-weight: bold;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.done {
  width: 100%;
  margin-top: -10px;
  margin-bottom: 10px;
  background-color: #eeffee;
  color: #50a050;
  font-size: 14px;
  padding: 12px;
  font-weight: bold;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
