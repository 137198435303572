/*
__Seed builder__v1.0
*/
/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  width: 100%;
  height: 100%; }

.searchBar {
  display: flex;
  background-color: white;
  height: 56px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  align-items: center;
  padding: 0px 40px;
  color: #343434;
  position: relative;
  z-index: 10; }
  .searchBar p {
    margin: 0; }
  .searchBar .searchBarTitle {
    margin: 0;
    font-size: 18px; }
  .searchBar .searchBarRight {
    display: flex;
    margin-left: auto;
    align-items: center; }
    .searchBar .searchBarRight .searchForm {
      margin-right: 40px;
      position: relative; }
      .searchBar .searchBarRight .searchForm input {
        border-radius: 4px;
        border: 2px solid #198CE5;
        padding: 5px 40px 5px 10px;
        background-color: #F5F5F5; }
      .searchBar .searchBarRight .searchForm .searchButton {
        color: #d5d5d5;
        position: absolute;
        right: 10px;
        top: 6px; }
        .searchBar .searchBarRight .searchForm .searchButton:hover {
          cursor: pointer; }
    .searchBar .searchBarRight .searchBarBtn {
      margin-right: 40px; }
    .searchBar .searchBarRight .profile {
      display: flex;
      align-items: center; }
      .searchBar .searchBarRight .profile .imgUser {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        align-self: center;
        margin-right: 10px; }
        .searchBar .searchBarRight .profile .imgUser img {
          width: 100%;
          display: inline;
          margin: 0 auto;
          height: auto; }

.searchBody {
  color: #686868;
  background-color: #F5F5F5;
  padding: 35px 70px 60px 70px;
  min-height: calc(100vh - 56px); }
  .searchBody .resultsTitle {
    font-size: 18px;
    font-weight: bold; }
  .searchBody .searchBodyInner {
    display: flex; }
    .searchBody .searchBodyInner .searchBodyFilters {
      margin-right: 40px;
      width: 220px; }
      .searchBody .searchBodyInner .searchBodyFilters .filtersTitle {
        font-size: 18px;
        font-weight: bold; }
      .searchBody .searchBodyInner .searchBodyFilters .btn {
        color: #686868;
        font-size: 14px;
        font-weight: bold;
        width: 100%;
        text-align: left;
        margin-bottom: 12px;
        padding: 10px 15px;
        border: 1px solid #CBCBCB;
        border-radius: 4px;
        background: #fff;
        display: block;
        text-decoration: none; }
      .searchBody .searchBodyInner .searchBodyFilters .btn.active {
        border-color: #198CE5;
        background-color: #198CE5;
        color: white;
        fill: white; }
      .searchBody .searchBodyInner .searchBodyFilters .advanceSearch {
        color: #198CE5; }
        .searchBody .searchBodyInner .searchBodyFilters .advanceSearch:hover {
          cursor: pointer; }
    .searchBody .searchBodyInner .searchBodyResults {
      width: 75%; }
      .searchBody .searchBodyInner .searchBodyResults .typeButtons {
        width: 100%;
        margin-bottom: 24px;
        display: flex;
        text-align: center; }
        .searchBody .searchBodyInner .searchBodyResults .typeButtons .btn {
          text-decoration: none;
          color: #686868;
          font-size: 15px;
          font-weight: bold;
          width: 50%;
          padding: 8px 25px;
          border: 1px solid #CBCBCB;
          background: none;
          display: block; }
          .searchBody .searchBodyInner .searchBodyResults .typeButtons .btn:first-child {
            border-radius: 4px 0 0 4px; }
          .searchBody .searchBodyInner .searchBodyResults .typeButtons .btn:last-child {
            border-radius: 0 4px 4px 0; }
          .searchBody .searchBodyInner .searchBodyResults .typeButtons .btn span {
            margin-right: 10px; }
        .searchBody .searchBodyInner .searchBodyResults .typeButtons .btn.active {
          border-color: #198CE5;
          background-color: #198CE5;
          color: white;
          fill: white; }
      .searchBody .searchBodyInner .searchBodyResults .cardsTitle {
        font-size: 18px;
        margin-bottom: 18px; }
      .searchBody .searchBodyInner .searchBodyResults .searchCard {
        background-color: white;
        border-radius: 4px;
        padding: 20px 25px;
        display: flex;
        margin-bottom: 16px;
        border: 1px solid #d5d5d5; }
        .searchBody .searchBodyInner .searchBodyResults .searchCard:hover {
          cursor: pointer;
          background-color: #fafafa; }
        .searchBody .searchBodyInner .searchBodyResults .searchCard:last-child {
          margin-bottom: 0; }
        .searchBody .searchBodyInner .searchBodyResults .searchCard .imgProject {
          align-self: center; }
          .searchBody .searchBodyInner .searchBodyResults .searchCard .imgProject img {
            width: 130px; }
        .searchBody .searchBodyInner .searchBodyResults .searchCard .imgUser {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          position: relative;
          overflow: hidden;
          align-self: center; }
          .searchBody .searchBodyInner .searchBodyResults .searchCard .imgUser img {
            width: 100%;
            display: inline;
            margin: 0 auto;
            height: auto; }
        .searchBody .searchBodyInner .searchBodyResults .searchCard .searchCardInfo {
          margin-left: 35px; }
          .searchBody .searchBodyInner .searchBodyResults .searchCard .searchCardInfo .cardTitle {
            font-size: 18px;
            font-weight: bold; }
          .searchBody .searchBodyInner .searchBodyResults .searchCard .searchCardInfo .compoundSubtitle {
            display: flex; }
            .searchBody .searchBodyInner .searchBodyResults .searchCard .searchCardInfo .compoundSubtitle svg {
              margin-left: -3px; }
            .searchBody .searchBodyInner .searchBodyResults .searchCard .searchCardInfo .compoundSubtitle .subtitle {
              margin-left: 10px; }
          .searchBody .searchBodyInner .searchBodyResults .searchCard .searchCardInfo .subtitle {
            font-size: 16px;
            text-transform: capitalize; }
          .searchBody .searchBodyInner .searchBodyResults .searchCard .searchCardInfo .ring {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            border: 3px solid #1fc778; }
          .searchBody .searchBodyInner .searchBodyResults .searchCard .searchCardInfo p {
            font-size: 14px;
            margin-top: 1rem; }

.noMatch {
  text-align: center;
  min-height: 100vh; }
  .noMatch .title {
    font-size: 32px;
    font-weight: bold; }
  .noMatch .subtitle {
    font-size: 32px;
    font-weight: 400; }
