/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  width: 100%;
  height: 100%; }

.title {
  font-family: "PT Serif", serif;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-size: 17px;
  margin-bottom: 10px; }

.sub {
  font-family: "PT Serif", serif;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-size: 14px;
  color: #777; }

.image {
  width: 100%;
  margin-top: 20px; }

.call {
  text-align: center;
  padding: 9px 14px;
  margin-top: 14px;
  font-size: 16px;
  color: #fff;
  background-color: #11C26F;
  border: none;
  cursor: pointer;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px; }
