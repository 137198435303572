/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module button {
  margin-right: 8px !important;
  margin-top: 6px; }

.module .buttonLink {
  background: none;
  border: none;
  padding: 0 !important;
  font-weight: bold;
  color: #413c6b;
  cursor: pointer;
  box-sizing: border-box; }
  .module .buttonLink:hover {
    box-sizing: border-box;
    text-decoration: underline; }

.module .buttonGreen {
  color: #ffffff;
  background-color: #11c26f; }
  .module .buttonGreen:hover {
    background-color: #0fa063; }

.module .mainButton {
  border-radius: 18px;
  width: 100%; }

.module .input, .module .select {
  background-color: #f5f5f5;
  border: solid 1px #cbcbcb;
  padding: 12px;
  margin-top: 6px; }

.module .button {
  background-color: #413c6b;
  color: #ffffff;
  height: 36px;
  border-radius: 18px; }
  .module .button.lg {
    height: 48px;
    border-radius: 24px; }

.module .select {
  height: 48px; }

/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  font-family: "PT Serif", serif;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  height: 100%;
  width: 100%; }

.background {
  position: absolute;
  z-index: -100;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
  background: url("/resources/images/wave.svg") no-repeat center center;
  background-size: cover;
  background-color: #29244e; }

.module .spacingContainer {
  padding-top: 20px; }

.module .spacingText {
  padding-top: 4px; }

.module .card {
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #cbcbcb;
  padding: 8px; }
  .module .card .main {
    color: #ffffff;
    border: none;
    padding: 12px; }
    .module .card .main .mainCardText {
      color: #ffffff;
      font-family: "Open Sans", sans-serif; }
  .module .card .mainCardTitle {
    font-size: 38px;
    letter-spacing: 1px;
    font-family: "Open Sans", sans-serif;
    font-weight: bolder; }

.module .title {
  font-family: "PT Serif", serif;
  font-size: 32px;
  font-weight: bold; }
  .module .title.titleWhite {
    color: #ffffff; }

.module .completeWidth {
  width: 100%; }

.module a.link {
  cursor: pointer;
  color: #198ce5; }
  .module a.link:hover {
    text-decoration: underline; }

.module .tableRight td {
  text-align: right; }

.module {
  font-family: "PT Serif", serif;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-family: "PT Serif", serif;
  height: 100%;
  width: 100%; }

.module .jumbotron {
  background-color: #29244e;
  padding: 25px 34px 10px 34px; }

.module .loading {
  position: absolute;
  margin-left: 130px;
  height: 100px;
  width: 100px; }

.module .back {
  position: absolute;
  height: 32px;
  top: 44px;
  cursor: pointer; }

.module .image {
  height: 75px;
  width: auto; }

.module .title {
  font-size: 32px;
  margin-top: 50px;
  margin-bottom: 20px;
  line-height: 1.4em; }

.module label {
  font-family: "PT Serif", serif;
  color: #6c757d;
  font-size: 14px;
  font-weight: bold; }

.module input,
select,
textarea {
  font-family: "PT Serif", serif;
  height: auto;
  padding-top: 8px !important;
  padding-bottom: 8px !important; }

.module h5 {
  font-family: "PT Serif", serif; }

.module .breadcrumb {
  font-family: "PT Serif", serif;
  font-size: 18px;
  font-weight: bold;
  color: #343434; }
  .module .breadcrumb.breadcrumbLight {
    color: #6c757d; }

.module .subtitle {
  font-family: "PT Serif", serif;
  font-size: 15px;
  color: #a0a0a0;
  line-height: 1.7em; }

.module button {
  background-color: #11c26f;
  color: #ffffff;
  min-height: 45px !important;
  border-radius: 24px;
  font-size: 16px; }
  .module button:hover {
    color: #ffffff;
    background-color: #0fa063; }

.error {
  width: 100%;
  margin-top: -10px;
  margin-bottom: 10px;
  background-color: #ffeeee;
  color: #a05050;
  font-size: 14px;
  padding: 12px;
  font-weight: bold;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
