/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  width: 100%;
  height: 100%; }

.item {
  padding: 2px 0px; }

.item input {
  margin-right: 7px; }
