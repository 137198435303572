/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  width: 100%;
  height: 100%; }

.fullwidth {
  max-width: 100%;
  margin: 0; }

.container {
  min-height: 100%; }

.row,
.innercol {
  min-height: 1px; }

.innerrow {
  min-height: 1px;
  margin: 0px; }

.col {
  padding: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.col.header {
  padding: 8px 0px 6px 0px;
  text-align: center; }

.col.header b {
  font-size: 13px; }

.content {
  position: relative;
  border: 1px solid #e7e7e7;
  background: #fff;
  width: 100%;
  height: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.content.other {
  margin-top: 20px; }

.footer {
  background-color: #FFBA13;
  visibility: hidden;
  max-width: 100%;
  margin: 12px 0px 0px 0px;
  padding: 4px;
  font-size: 13px;
  color: #fff;
  text-align: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px; }

.brand {
  background-color: #343434;
  color: #fff;
  max-width: 100%;
  margin-top: 5px;
  margin-bottom: 3px;
  padding: 4px;
  font-size: 13px;
  text-align: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px; }
