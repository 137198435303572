/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  width: 100%;
  height: 100%;
  padding: 14px; }

.text {
  font-size: 15px;
  margin-bottom: 5px; }

.type {
  display: inline-block;
  color: #1CAB68;
  border: 1px solid #1CAB68;
  width: auto;
  font-size: 13px;
  padding: 3px 7px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  border-radius: 12px; }

.dateContainer {
  float: right;
  font-size: 14px;
  padding: 4px 0px; }

.dateContainer .image,
.dateContainer .date {
  float: left; }

.dateContainer .image {
  width: 22px;
  height: 22px;
  margin-right: 10px;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%; }

.dateContainer .date {
  font-style: italic;
  color: #777; }

.options {
  position: absolute;
  background: #fff;
  right: 30px;
  display: none; }

.options .option {
  display: inline-block;
  margin: 2px;
  font-size: 13px;
  color: white;
  padding: 4px 5px;
  cursor: pointer;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%; }

.delete {
  background-color: #FF8050; }
