/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  width: 100%;
  height: 100%;
  overflow: auto; }

.video {
  width: 100%;
  height: 280px; }

.content {
  background-color: #fff;
  border: 1px solid #e7e7e7;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 10px;
  padding: 20px;
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 1.5em; }
