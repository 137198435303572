/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.background {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-color: #29244e; }

.title {
  font-family: "PT Serif", serif;
  font-size: 23px;
  margin-top: -4px;
  margin-bottom: 34px;
  margin-left: 45px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.85);
  width: 100%; }
  .title span {
    display: inline-block;
    font-size: 14px;
    margin-left: 7px;
    color: rgba(255, 255, 255, 0.7); }

.logoCanou {
  display: inline-block;
  height: 80px;
  width: auto;
  margin-top: 30px;
  margin-left: 40px; }
