/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  width: 100%;
  height: 100%; }

.dashboardsBody {
  padding: 40px 95px 250px 95px;
  color: #686868; }
  .dashboardsBody .dashboardsMenu {
    width: 100%;
    display: flex;
    margin-bottom: 25px; }
    .dashboardsBody .dashboardsMenu .btn {
      text-decoration: none;
      color: #686868;
      font-size: 15px;
      font-weight: bold;
      width: 50%;
      padding: 8px 25px;
      border: 1px solid #CBCBCB;
      background: #fff;
      display: block;
      border-radius: 0;
      text-align: center; }
      .dashboardsBody .dashboardsMenu .btn:first-child {
        border-radius: 4px 0 0 4px; }
      .dashboardsBody .dashboardsMenu .btn:last-child {
        border-radius: 0 4px 4px 0; }
      .dashboardsBody .dashboardsMenu .btn span {
        margin-right: 10px; }
    .dashboardsBody .dashboardsMenu .btn.active {
      border-color: #198CE5;
      background-color: #198CE5;
      color: white;
      fill: white; }
