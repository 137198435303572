/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  width: 100%;
  height: 100%; }
  .module p {
    margin: 0; }

.title {
  position: relative;
  font-size: 23px;
  top: 35px;
  left: 20px;
  font-family: "PT Serif", serif; }

.projectSub {
  font-size: 14px;
  margin-left: 12px; }

.upload {
  padding: 20px 80px 10px 80px; }
  .upload .options {
    display: flex;
    justify-content: flex-end; }
  .upload .uploadButton,
  .upload .bulkButton,
  .upload .phasesButton {
    display: inline-block;
    background-color: #11C26F;
    color: white;
    border: none;
    border-radius: 45px;
    padding: 8px 25px;
    width: auto;
    height: auto;
    z-index: 10;
    margin-bottom: 24px; }
  .upload .phasesButton {
    margin-left: 10px;
    background-color: #a0a0a0 !important; }
  .upload .bulkButton {
    margin-left: 10px; }
  .upload .projectsContainer .project {
    background-color: white;
    border: 1px solid #dddddd;
    margin-bottom: 30px;
    padding: 30px; }
    .upload .projectsContainer .project:last-child {
      margin-bottom: 0px; }
    .upload .projectsContainer .project .projectName {
      font-size: 24px;
      margin-bottom: 25px; }
    .upload .projectsContainer .project .buttonOption {
      display: inline-block;
      float: right;
      margin-top: -40px;
      width: 30px;
      height: 30px;
      cursor: pointer; }
    .upload .projectsContainer .project .member:first-child {
      border-top: 1px solid #dbdbdb; }
    .upload .projectsContainer .project .member {
      display: flex;
      align-items: center;
      margin-left: 10px;
      border-bottom: 1px solid #dbdbdb;
      padding: 10px 0; }
      .upload .projectsContainer .project .member .profilePic {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 35px;
        vertical-align: middle;
        background-clip: padding-box;
        background-size: cover;
        background-position: center center; }
      .upload .projectsContainer .project .member .memberInfo {
        margin-right: 40px; }
        .upload .projectsContainer .project .member .memberInfo .name {
          font-weight: bold; }
        .upload .projectsContainer .project .member .memberInfo .mail {
          font-weight: 400; }
      .upload .projectsContainer .project .member .deleteButton {
        flex-grow: 1;
        text-align: right; }
        .upload .projectsContainer .project .member .deleteButton button {
          border: none;
          background-color: none; }
        .upload .projectsContainer .project .member .deleteButton svg {
          color: #686868; }
        .upload .projectsContainer .project .member .deleteButton:hover svg {
          color: #343434; }
  .upload .projectsContainer .addMemberBtn {
    color: #198CE5;
    border: 1px solid #198CE5;
    border-radius: 24px;
    padding: 6px 15px;
    margin-top: 20px;
    margin-bottom: 24px;
    display: inline-block; }

.uploadModalContainer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  background-color: rgba(91, 85, 139, 0.4); }
  .uploadModalContainer .uploadModal {
    padding: 40px 30px;
    background-color: white;
    box-shadow: 0 3 20 rgba(0, 0, 0, 0.16);
    min-width: 700px;
    width: 60%; }
    .uploadModalContainer .uploadModal .instructions {
      margin-bottom: 15px; }
    .uploadModalContainer .uploadModal .downloadTemplate {
      width: 100%;
      padding: 8px 15px;
      border: none;
      background-color: #198CE5;
      border-radius: 24px;
      color: white;
      margin-bottom: 20px; }
    .uploadModalContainer .uploadModal .bottomButtons {
      display: flex;
      justify-content: space-between; }
      .uploadModalContainer .uploadModal .bottomButtons .btn {
        padding: 8px 15px;
        width: 48%;
        border: none;
        background-color: #11C26F;
        border-radius: 24px;
        color: white; }
