/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  background: #fff; }

.form {
  padding-top: 20px; }

.form .label {
  color: #5f5f5f;
  padding-bottom: 5px;
  padding-left: 4px;
  padding-right: 4px; }

.form input[type=text],
.form select,
.form textarea {
  width: 100%;
  padding: 8px 14px;
  margin-bottom: 4px;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  background: white;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.form textarea {
  height: 150px; }

.call {
  position: relative;
  width: 100%;
  display: inline-block;
  padding: 9px 14px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 15px;
  color: #fff;
  background-color: #11C26F;
  border: none;
  cursor: pointer;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px; }

.call:hover {
  background-color: #11B24F; }
