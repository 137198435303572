/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  width: 100%;
  height: 100%; }

.header {
  width: 100%;
  padding: 23px 20px;
  background-color: #198CE5;
  color: #fff;
  font-size: 20px;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0; }

.content {
  padding: 15px 20px 30px 20px;
  background: #fff;
  max-height: 500px;
  overflow: auto;
  -webkit-border-radius: 0px 0px 5px 5px;
  -moz-border-radius: 0px 0px 5px 5px;
  -ms-border-radius: 0px 0px 5px 5px;
  border-radius: 0px 0px 5px 5px; }

.content input {
  background-color: #f5f5f5;
  border: none;
  padding: 8px 10px;
  margin-top: 6px;
  width: 250px; }

.call {
  position: relative;
  width: 100%;
  display: inline-block;
  padding: 9px 14px;
  text-align: center;
  margin-top: 20px;
  font-size: 15px;
  color: #fff;
  background-color: #11C26F;
  border: none;
  cursor: pointer;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px; }
