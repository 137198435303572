/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  width: 100%;
  height: 100%; }

.header {
  width: 100%;
  padding: 23px 20px;
  background-color: #198CE5;
  color: #fff;
  font-size: 20px;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0; }

.module .loading {
  position: absolute;
  margin-left: 185px;
  height: 50px;
  width: 50px; }

.content {
  padding: 20px 20px 30px 20px;
  background: #fff;
  -webkit-border-radius: 0px 0px 5px 5px;
  -moz-border-radius: 0px 0px 5px 5px;
  -ms-border-radius: 0px 0px 5px 5px;
  border-radius: 0px 0px 5px 5px; }

.input {
  width: 100%;
  padding: 10px 14px;
  margin-bottom: 2px;
  font-size: 18px;
  border: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.customers,
.colors,
.types {
  margin-top: 14px; }

.customers b,
.colors b,
.types b {
  display: inline-block;
  margin-bottom: 7px; }

.typeContainer {
  border: 1px solid #e0e0e0;
  padding: 8px 16px;
  height: 120px;
  overflow: auto; }

.call {
  position: relative;
  width: 100%;
  display: inline-block;
  padding: 9px 14px;
  text-align: center;
  margin-top: 20px;
  font-size: 15px;
  color: #fff;
  background-color: #11C26F;
  border: none;
  cursor: pointer;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px; }

.error {
  width: 100%;
  margin-top: -10px;
  margin-bottom: 10px;
  background-color: #ffeeee;
  color: #a05050;
  font-size: 14px;
  padding: 12px;
  font-weight: bold;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.template {
  display: inline-block;
  padding: 12px 7px;
  font-size: 17px; }

.instructions {
  display: inline-block;
  padding: 5px 7px;
  font-size: 14px;
  color: #777; }

.call:hover {
  background-color: #11B24F; }
