/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  width: 100%;
  height: 100%; }

.item {
  display: inline-block;
  padding: 4px 12px;
  margin: 3px 3px;
  border: 1px solid;
  cursor: pointer;
  min-height: 31px;
  min-width: 47px;
  text-align: center;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  border-radius: 14px; }

.tick {
  display: inline-block; }
