/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  width: 100%;
  height: 100%; }

.colors {
  width: 100%;
  margin-top: -4px; }

.colors > * {
  display: inline-block; }

.color {
  width: 19px;
  height: 6px;
  margin-right: 3px;
  cursor: pointer; }

.content {
  width: 100%;
  color: #222;
  font-size: 14px;
  padding: 1px 0px;
  cursor: pointer; }

.content.valid {
  text-decoration: underline;
  text-decoration-color: #40a040; }

.content.invalid {
  text-decoration: line-through;
  text-decoration-color: #FF8050; }

.options {
  position: absolute;
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  right: 10px;
  margin-top: 2px;
  display: none;
  cursor: pointer;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  border-radius: 14px; }

.options.focused {
  border-image-source: none; }

.options > * {
  display: inline-block; }

.options .optionItem {
  display: inline-block;
  padding: 0px;
  margin: 2px;
  font-size: 16px;
  padding: 4px 5px; }

.validate {
  color: #40a040; }

.invalidate {
  color: #FF8050; }

.edit {
  color: #198CE5; }

.delete {
  color: #555; }

.footer {
  width: 100%; }

.tags {
  padding-top: 3px;
  padding-right: 32px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.tags > * {
  display: inline-block; }

.tag {
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 12px;
  color: #444;
  border: 1px solid #e0e0e0;
  background: #eee;
  margin-right: 5px;
  cursor: pointer;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%; }
