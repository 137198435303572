/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  width: 100%;
  height: 100%; }

.greenFont {
  color: #11C26F; }

.redFont {
  color: #F96276; }

.purpleFont {
  color: #928DAF; }

.darkPurpleFont {
  color: #413C6B; }

.ChartCard {
  background-color: white;
  border: 1px solid #ECECEC;
  padding: 30px;
  display: flex;
  max-height: 330px; }
  .ChartCard .title {
    font-size: 18px;
    margin-bottom: 20px;
    font-family: "Open Sans";
    font-weight: bold; }
  .ChartCard .ChartCardInfo {
    padding-right: 70px;
    text-align: left; }
    .ChartCard .ChartCardInfo .data {
      display: flex;
      border-bottom: 1px solid #ECECEC;
      padding: 10px 0; }
      .ChartCard .ChartCardInfo .data .dataNumber {
        flex-grow: 1;
        text-align: right;
        margin-left: 5px; }
    .ChartCard .ChartCardInfo .data p {
      margin: 0;
      font-size: 14px; }
  .ChartCard .ChartCardInfo h3 {
    margin: 0; }
  .ChartCard .ChartCardGraphic {
    flex-grow: 1;
    height: 200px; }
    .ChartCard .ChartCardGraphic ul {
      list-style-type: none;
      padding-left: 0px; }
    .ChartCard .ChartCardGraphic li > div {
      height: 200px; }
  .ChartCard .cc-3 {
    width: 33%; }

.ChartCard.Transparent {
  border-color: #F7F7F7;
  background-color: #F7F7F7; }

.BolaCard {
  background-color: #928DAF;
  padding: 30px;
  display: flex;
  max-height: 330px;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans"; }
  .BolaCard .circle {
    border-radius: 50%;
    border: 4px solid #F7F7F7;
    padding: 30px;
    text-align: center;
    height: 260px;
    width: 260px; }
    .BolaCard .circle .number {
      font-size: 64px;
      color: #F7F7F7;
      margin-bottom: 0; }
    .BolaCard .circle .title {
      font-size: 16px;
      color: #F7F7F7; }
      .BolaCard .circle .title .bigTitle {
        font-size: 32px;
        color: #F7F7F7; }

.DoughnutChartCard {
  display: block !important; }

.BarChartCard {
  display: block !important; }

.LargeChardCard {
  max-height: 420px !important; }

.analyticCard {
  background-color: white;
  border: 1px solid #ECECEC;
  color: #686868;
  padding: 30px;
  display: flex;
  align-items: center; }
  .analyticCard .analyticCardLeft {
    flex-grow: 1; }
    .analyticCard .analyticCardLeft .title {
      font-size: 17px;
      font-weight: bold; }
    .analyticCard .analyticCardLeft .week {
      font-size: 17px;
      margin-bottom: 0px; }
    .analyticCard .analyticCardLeft .info {
      font-size: 14px;
      margin-bottom: 0px; }
  .analyticCard .btnContainer {
    margin-right: 40px; }
    .analyticCard .btnContainer .btn {
      background-color: #ECECEC;
      border-radius: 50px;
      padding: 12px 40px;
      border: none;
      color: #686868;
      font-weight: bold; }
  .analyticCard .data {
    padding-right: 30px;
    margin-right: 20px;
    border-right: 1px solid #CBCBCB; }
    .analyticCard .data:last-child {
      padding-right: 0px;
      margin-right: 0px;
      border-right: none; }
    .analyticCard .data .subtitle {
      font-size: 12px;
      color: #CBCBCB; }
    .analyticCard .data .dataNumberContainer {
      display: flex; }
      .analyticCard .data .dataNumberContainer .dataNumber {
        text-align: center;
        margin-right: 20px; }
        .analyticCard .data .dataNumberContainer .dataNumber:last-child {
          margin-right: 0px; }
        .analyticCard .data .dataNumberContainer .dataNumber p:last-child {
          margin-bottom: 0px; }
        .analyticCard .data .dataNumberContainer .dataNumber .number {
          font-size: 19px;
          font-weight: bold;
          margin-bottom: 10px; }
