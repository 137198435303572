/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  width: 100%;
  height: 100%; }

.module .listContainer {
  min-height: 150px;
  max-height: 300px;
  overflow: auto; }

.module .formContainer {
  margin-top: 15px;
  height: 100%; }

.module .formContainer textarea {
  width: 100%;
  height: 70px;
  border: 1px solid #e0e0e0;
  resize: none;
  padding: 10px; }

.module .formContainer select {
  width: 100%;
  border: 1px solid #e0e0e0;
  resize: none;
  background: #fff;
  color: #888; }

.module .formContainer button {
  position: relative;
  width: 100%;
  display: inline-block;
  padding: 9px 14px;
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  color: #fff;
  background-color: #279af3;
  border: none;
  cursor: pointer;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px; }
