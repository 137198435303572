/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module input {
  font-family: inherit; }

.module {
  font-family: "PT Serif", serif;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  height: 100%;
  position: relative;
  display: flex; }

.module .container {
  height: 100%;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  transition: margin-left 250ms ease-out, transform 250ms ease-out;
  width: 245px;
  color: white;
  position: relative;
  color: #ffffff;
  justify-content: space-between; }

.module .footer {
  position: relative;
  bottom: 12px;
  margin-top: 12px; }

.module .body {
  min-height: 80px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: block; }

.module .loading {
  margin-top: 100px; }

.module .header {
  margin: 12px;
  position: relative;
  display: block; }

.module .element {
  width: 219px;
  display: flex;
  flex-direction: column;
  margin: 12px;
  margin-top: 0px; }

.module .projects {
  background: inherit; }

.module .menu {
  display: flex;
  justify-content: space-between; }

.module .elementMenu {
  display: block; }

.module .logo {
  margin-top: 4px;
  object-fit: contain;
  height: 24px;
  width: auto; }

.module .menuHamburguer {
  margin-top: -4px;
  object-fit: contain;
  height: 13px;
  width: auto; }

.module .elementMenu {
  display: block;
  font-size: 1em; }

.module .footer {
  position: relative;
  display: block; }

.module .team {
  width: 219px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.module .flexColumnElement {
  display: flex;
  flex-direction: column;
  height: 100%; }

.module .elementRight :hover {
  cursor: pointer; }

.module .select {
  width: 219px; }

.module .select * {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px !important; }

.module button {
  font-family: inherit;
  border: none;
  border-radius: 3px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  width: 219px; }

.module button:hover {
  cursor: pointer; }

.module .navButton {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: inherit; }

.module .navButton.active .button {
  background-color: #198ce5;
  color: white; }

.module .button {
  color: #cbcbcb;
  border-radius: 3px;
  margin-bottom: 6px;
  background-color: #6c757d;
  text-align: left;
  padding: 7px 10px 7px 16px; }

.module .button:hover {
  background-color: #198ce5;
  color: #f7f7f7; }

.module .buttonOption {
  float: right;
  width: 30px;
  padding: 2px 0px;
  font-size: 13px;
  text-align: right;
  z-index: 2; }

.module .buttonSecondary {
  padding: 6px;
  padding-left: 6px;
  color: #928daf;
  border-radius: 3px;
  margin-bottom: 6px;
  text-align: left;
  background-color: #29244e; }

.module .buttonSecondary:hover {
  color: #f7f7f7; }

.module .teamSection {
  display: flex;
  flex-direction: row; }

.module .teamElementDefault {
  margin-left: 7px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px dashed rgba(255, 255, 255, 0.1);
  background-color: #413c6b;
  cursor: pointer; }

.module .teamElement {
  margin-left: 7px;
  cursor: pointer; }

.module .teamImage {
  display: inline-block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  object-fit: cover;
  overflow: hidden; }

.module .teamAmmount {
  padding: 8px; }

.module .buttonAdd {
  background-color: #29244e;
  color: #928daf;
  width: auto;
  height: auto; }

.module .buttonAddIcon {
  font-size: 19px;
  font-weight: bold; }

.module .buttonAdd:hover {
  color: #f7f7f7; }

.module .sectionTitle {
  margin-bottom: 8px;
  font-size: 11px;
  color: #928daf;
  text-transform: uppercase; }

.module .canvasSection {
  padding-top: 10px; }
