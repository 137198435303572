/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  width: 100%;
  height: 100%;
  overflow: auto; }

.navContainer {
  position: relative;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 28px;
  width: 300px;
  left: 50%;
  margin-left: -150px; }

.itemContainer {
  height: 100%; }
