/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module .container {
  position: relative;
  width: 500px;
  height: 550px;
  left: 50%;
  margin-left: -250px;
  top: 50%;
  margin-top: -275px; }

.module .close {
  position: absolute;
  color: rgba(255, 255, 255, 0.6);
  width: 35px;
  height: 35px;
  margin-top: -24px;
  margin-left: 476px;
  border: none;
  background: rgba(255, 255, 255, 0.3);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%; }
