/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  width: 100%;
  height: 100%; }

.line {
  position: relative;
  background-color: #279af3;
  height: 2px;
  top: 50%;
  width: 100%; }

.item {
  position: relative;
  top: 50%;
  background: #fff;
  height: 28px;
  margin-left: -13px;
  width: 28px;
  font-size: 16px;
  text-align: center;
  color: #666;
  cursor: pointer;
  border: 2px solid #279af3;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%; }

.item.active {
  background: #279af3;
  color: #fff; }
