/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  width: 100%;
  height: 100%; }

.chartRow {
  margin-bottom: 20px; }
  .chartRow:last-child {
    margin-bottom: 0px; }

.pageTitle {
  margin-bottom: 20px;
  font-size: 17px;
  font-family: "Open Sans";
  font-weight: bold;
  text-transform: uppercase; }
