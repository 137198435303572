/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  width: 100%;
  height: 100%; }

.loading {
  position: relative;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  margin-top: -40px;
  margin-left: -40px; }
