/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  width: auto;
  height: auto; }

.module .caption {
  position: absolute;
  margin-top: -25px;
  font-size: 12px;
  color: white;
  padding: 2px 7px;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 2;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px; }

.module .caption.bottom {
  margin-top: 5px; }

.module caption.focused {
  border-image-source: none; }
