/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #fafafa; }

.module .columns {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start; }

.module .rows {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  height: 100%;
  min-height: 100%; }

.module .rightRow {
  width: 100%; }

.module .sidenav {
  overflow: auto;
  display: flex;
  align-items: stretch;
  align-content: stretch;
  height: 100vh;
  width: 245px;
  position: relative;
  background-color: #343434;
  min-height: 100vh; }

.module .navbar {
  display: block; }

.module .content {
  display: block;
  overflow: auto;
  width: 100%;
  height: 100%; }
