/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/
.module {
  width: 100%;
  height: 100%;
  overflow: auto; }

.header {
  width: 100%;
  padding: 23px 20px;
  background-color: #198CE5;
  color: #fff;
  font-size: 20px;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0; }

.content {
  padding: 15px 20px 30px 20px;
  background: #fff;
  -webkit-border-radius: 0px 0px 5px 5px;
  -moz-border-radius: 0px 0px 5px 5px;
  -ms-border-radius: 0px 0px 5px 5px;
  border-radius: 0px 0px 5px 5px; }

.item {
  padding: 12px 10px; }

.subitem {
  padding-top: 10px; }

.title {
  font-size: 17px;
  color: #198CE5; }

.description {
  font-size: 15px;
  color: #777; }

.switch {
  float: right;
  margin-top: -35px; }
